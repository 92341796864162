import React, { PropsWithChildren } from 'react'

export const ShowValid: React.FC<PropsWithChildren<{ valid: boolean }>> = ({ valid, children }) => {

    if (!valid) return null

    return (
        <>
            {children}
        </>
    )
}