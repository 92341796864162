import { useBookings } from '@/api/hooks/useBookings'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from '@/api/hooks/useTree'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import SearchIcon from '@/components/icons/SearchIcon'
import FormLoader from '@/components/ui/form/FormLoader'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { translate } from '@/i18n'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { printPDF } from '@/utils/func/print'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import DoubleScrollbar from 'react-double-scrollbar'
import styled from 'styled-components'

type ReportModalProps = {
	report: IReport
	close: () => void
}

const translations = {
    display: 'ФИО Клинера',
    section: 'Участок',
    placement: 'Помещение',
}
const translateCol = (term: string) => translations[term] ?? term

function filterItems(items = [], filter) {
	return items.filter((item) => {
		return Object.entries(filter).every(([key, value]) => {
			if (value !== '') {
				const inputValue = (value as string).trim().toLowerCase()
				const itemValue = String(item[key]).toLowerCase()
				return itemValue
					.split(' ')
					.some((w) => inputValue.split(' ').some((i) => w.startsWith(i)))
			}
			return true
		})
	})
}

const name = 'Отчет по площадям'

const CleaningReport: React.FC<ReportModalProps> = ({
	report,
	close,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [filters, setFilters] = useState<Record<string, string>>({})
    const cols = useSettingsSelector(settings => settings.reportColumns, [])
    const section = useSettingsSelector(settings => settings.section, '')
    const { metadata, isLoading: isLoadingMeta } = useMetadata()
    const { layers: treeData } = useTree()
    const nodes = treeData?.nodes || []

    const { data, isLoading } = useBookings({
        page: 1,
        perPage: 1000,
        moment: 'current',
    })
	const columns = ['display', 'section', 'placement', ...cols] || []

    const type = useMemo(() => {
        if (!metadata) return { uid: '', props: [] }
        const layerTypes: any[] = Object.values(metadata?.layers) || []
        const typeObj = layerTypes.find(layer => layer.name == section)
        const fields = Object.values<any>(typeObj?.plugin_data).find(v => v?.['fields'])?.fields

        return {
            uid: typeObj?.uid || '',
            props: fields || []
        }
    }, [metadata, isLoadingMeta])

    const layers = useMemo(() => nodes.filter(n => n['type_uid'] == type.uid), [metadata, nodes])

	const reportItems = useMemo(() => {
        const dataItems = data?.items || []

		return dataItems.map((item) => {
            const node = layers.find(n => n.id == Number(item.parent_layer_id))
            if (!node) return null
            const fields = Object.values(node.plugin_data).find(v => Array.isArray(v)) || []
            const parentNode = nodes.find(n => n.id == Number(node.parent))

            let newItem = {
                id: item.id,
                display: item.user,
                section: parentNode?.name || '',
                placement: item.location.at(-1),
            }

            cols.forEach(col => {
                const prop = type.props.find(p => p.name.toLowerCase().includes(col.toLowerCase()))
				if (!prop) return
                newItem[col] = fields.find(v => v.field_id == prop.id)?.value || ''
            })

            return newItem as any
        }).filter(v => v)
	}, [data, layers])
	const reportData = filterItems(reportItems, filters).map((item, idx) => ({ ...item, num: idx + 1}))

	const handleFilters = (
		column: string,
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setFilters({
			...filters,
			[column]: event.target.value,
		})
	}


    const pdfCols = useMemo(() => columns.map(col => ({ header: translateCol(col), dataKey: col })), [columns])
    const csvCols = useMemo(() => columns.map(col => ({ label: translateCol(col), key: col })), [columns])

	return (
		<ModalWrapper>
			<Header>
				<Title>{name}</Title>
                <ExportWrapper>
                    <ReportButton
                        onClick={() => printPDF({
                            name: name + ' ' + formatToReport(new Date()),
                            columns: pdfCols,
                            body: reportData,
                            orientation: 'l',
							size: 6,
                        })}
                    >
                        PDF
                    </ReportButton>
                    <CSVLink
                        data={reportData}
                        headers={csvCols}
                        separator={";"}
                        filename={name + ' ' + formatToReport(new Date())}
                    >
                        <ReportButton>CSV</ReportButton>
                    </CSVLink>
                    <Close color="#000" onClick={close} />
                </ExportWrapper>
			</Header>

            <div>
                <DoubleScrollbar>
                    <Table>
                            <thead>
                                <tr>
                                    {columns.map((col) => (
                                        <th key={col}>{translateCol(col)}</th>
                                    ))}
                                </tr>
								<tr>
									{columns.map((col) => (
										<th className="search-row" key={col}>
											<div className="search-box">
												<SearchInput
													placeholder="Поиск"
													onChange={(event) => handleFilters(col, event)}
												/>
												<SearchIcon />
											</div>
										</th>
									))}
								</tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={columns.length + 1}>
                                            <FormLoader isLoading={true} />
                                        </td>
                                    </tr>
                                ) : reportData.length ? (
                                    reportData
                                        .slice((currentPage - 1) * 20, currentPage * 20)
                                        .map((spot) => (
                                            <ReportRow
                                                key={spot.id}
                                                columns={columns}
                                                item={spot}
                                            />
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan={columns.length + 1}>
                                            <NotFound>{translate('no-results')}</NotFound>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                </DoubleScrollbar>
            </div>
			

			<Pagination
				inverse
				currentPage={currentPage}
				total={reportData.length || 0}
				handlePageChange={setCurrentPage}
			/>

		</ModalWrapper>
	)
}

export default CleaningReport


const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 16px 0;

	/* table-layout: fixed; */
	th:first-child,
	td:first-child {
		width: 1%;
		white-space: nowrap;
	}
	th:last-child,
	td:last-child {
		width: 90px;
	}

	th,
	td {
		padding: 8px;
		border: 1px solid #cccccc;
		border-collapse: collapse;
		vertical-align: middle;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.search-row {
		padding: 0;
	}
	.search-box {
		display: flex;
		align-items: center;
		padding-right: 8px;
	}

	th {
		color: #000;
		font-size: 12px;
		font-weight: 700;
		line-height: 14px;
	}

	td {
        font-size: 12px;
		& > div {
			display: flex;
			align-items: center;
		}
	}
`

const ReportRow = ({ item, columns }) => {
	const cols = columns
		.map((column) => {
			let data = item[column]

			return {
				key: column,
				value: data,
			}
		})
		.filter((v) => v)

	return (
		<tr>
			{cols.map(({ key, value }) => (
				<td key={key}>
					<div style={{ display: 'flex', gap: '8px' }}>
							{value}
					</div>
				</td>
			))}
		</tr>
	)
}

const ModalWrapper = styled.div<{ $maxWidth?: number }>`
	background: #ffffff;
	max-width: 1640px;
	width: 100%;
	max-height: 100%;
	max-height: var(--app-height);
	padding: 30px 40px;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 20001;
	border-radius: 8px;

	${media.lg`
    padding: 20px;
  `}
	${media.md`
    padding: 10px;
  `}
`

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
	justify-content: center;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const ReportButton = styled.button`
	border: 2px solid #079dac;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 1.6rem;
	color: #079dac;
	padding: 1.2rem 2rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
      &:not(:last-child) {
          margin-right: 0.8rem;
      }
  }

  
  ${media.lg`
      margin-top: 20px;
  `}
`


const SearchInput = styled.input`
	width: 100%;
	border: none;
	padding: 8px 12px;
	outline: none;
	font-size: 14px;
`