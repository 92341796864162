import React from 'react'

const BackIcon = ({ width = 29, height = 27 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3125 11.5L11.6875 5.5L18.0625 11.5"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6875 26H11.6875V6"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default BackIcon
