import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { ProjectService } from '../services/project.service'
import { useProject } from '@/hooks/useProject'
import { useIntl } from 'react-intl'

export const useMetadata = () => {
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()
	const intl = useIntl()

	const { data, isLoading, refetch } = useQuery(
		['metadata', workspaceId, projectId],
		() => ProjectService.getMetadata({ workspaceId, projectId }),
		{
			enabled: Number(workspaceId) > 0 && Number(projectId) > 0,
			// keepPreviousData: true,
			select: ({ data }) => {
				const nodes = data?.metablock.nodes
					? Object.values(data?.metablock?.nodes)
					: []
				return {
					data,
					status: data.status,
					rawNodes: data?.metablock.nodes,
					nodes,
					layers: data.metablock.layers,
				}
			},
			onError: (e) => {
				console.error(e)
				enqueueToast(
					{
						title: intl.formatMessage({ id: 'error' }),
						message: intl.formatMessage({ id: 'error-load-metadata' }),
					},
					{ variant: 'error' },
				)
			},
		},
	)

	return { metadata: data, data, isLoading, refetch }
}
