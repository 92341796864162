import React from 'react'
import { getIn, useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { Input } from './Input'
import styled from 'styled-components'
import { dateFnsLocale } from '@/App'

export const DatePickerField = ({ name, autocomplete = 'on', ...props }) => {
  const { setFieldValue, touched, errors } = useFormikContext()
  const [field] = useField(props.field)
  const errorMessage = getIn(touched, field.name) && getIn(errors, field.name)

  return (
    <InputWrapper>
      <DatePicker
        timeCaption="Время"
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="Pp"
        {...field}
        name={field.name}
        selected={(field.value && new Date(field.value)) || null}
        showTimeSelect
        locale={dateFnsLocale}
        autoComplete="off"
        customInput={<CustomDatePickerInput />}
        onChange={(val) => {
          setFieldValue(field.name, val)
        }}
        {...props}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputWrapper>
  )
}

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

export const CustomDatePickerInput = styled(Input)`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.background.primary};
`

export const ErrorMessage = styled.div`
  line-height: 24px;
  color: rgb(251, 105, 94);
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 5px;
  border-radius: 3px;
  transition: background-color 0.15s ease-in-out 0s;
`
