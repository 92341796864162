import React from 'react'
import styled from 'styled-components'
import { ProjectTree } from '@/api/project'
import TreeItem from './TreeItem'
import TreeList from './TreeList'

const TreeView: React.FC<{ data: ProjectTree[] }> = ({ data }) => {
	if (!data || !data.length) return null

	return (
		<ListContainer>
			<TreeList $expanded={true}>
				{data.map((item) => (
					<TreeItem depth={1} tree={item} key={item.id} />
				))}
			</TreeList>
		</ListContainer>
	)
}

export default TreeView

const ListContainer = styled.div`
    padding: 0.8rem 0;
    overflow-y: auto;
    height: 100%;
`
